import CategoriesRequest from '@/@api/categories'
import { ref, computed } from '@vue/composition-api'
// import store from '@/store'
// import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { debouncedWatch } from '@vueuse/core'

const categories = ref([])

export default function usecategories() {
  // Use toast
  const toast = useToast()

  const refCategoriesListTable = ref(null)

  // const categories = ref([])

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: '#', sortable: true },
    { label: 'Label', key: 'label', sortable: true },
    {
      label: 'Categoría',
      key: 'name',
      sortable: true,
      thStyle: { width: '100%' },
    },
    { label: 'Tipo', key: 'type', sortable: true },
    { label: 'Pathname', key: 'pathname', sortable: true },
    { label: 'Activo', key: 'status', sortable: true },
    { label: 'Acciones', key: 'actions' },
  ]
  const perPage = ref(10)
  const totalCategories = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refCategoriesListTable.value ? refCategoriesListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCategories.value,
    }
  })

  const refetchData = () => {
    refCategoriesListTable.value.refresh()
  }

  debouncedWatch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData()
  }, { debounce: 400 })

  const fetchCategories = (ctx, callback) => {
    CategoriesRequest.list({
      params: {
        q: searchQuery.value,
        paginate: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role: roleFilter.value,
        plan: planFilter.value,
        status: statusFilter.value,
      },
    })
      .then(response => {
        const { data, total } = response.data.data

        callback(data.map(category => ({ ...category, loading: false })))
        totalCategories.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching categories list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  async function fetchCategoriesSelector(isMemo = false) {
    if (isMemo && categories.value.length) {
      return Promise.resolve(categories.value)
    }

    try {
      const { data } = await CategoriesRequest.select()

      const list = data.data.map(category => ({
        id: category.id,
        value: category.label?.toUpperCase(),
        label: category.name,
        type: category.type ?? 'banner',
      }))

      categories.value = list

      return list
    } catch (error) {
      throw new Error(error)
    }
  }

  async function categoryStore(body) {
    try {
      const form = { ...body, uri: '/categories' }

      const { data } = await CategoriesRequest.store(form)

      toast({
        component: ToastificationContent,
        props: {
          title: data.message,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    } catch (error) {
      throw new Error(error)
    }
  }

  async function categoryUpdate(id, body) {
    try {
      const form = { ...body, uri: '/categories' }

      const { data } = await CategoriesRequest.update(id, form)

      toast({
        component: ToastificationContent,
        props: {
          title: data.message,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    } catch (error) {
      throw new Error(error)
    }
  }

  async function categoryDelete(id) {
    try {
      const { data } = await CategoriesRequest.destroy(id)

      toast({
        component: ToastificationContent,
        props: {
          title: data.message,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    } catch (error) {
      throw new Error(error)
    }
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  fetchCategoriesSelector(true).then(list => {
    categories.value = list
  })

  const resolveIsoCategory = iso => {
    if (!iso) return ''

    const dic = categories.value.reduce((acc, category) => {
      acc[category.value?.toUpperCase()] = category.label

      return acc
    }, {})

    return dic[iso] ?? iso
  }

  return {
    categories,
    fetchCategories,
    fetchCategoriesSelector,
    tableColumns,
    perPage,
    currentPage,
    totalCategories,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refCategoriesListTable,

    resolveIsoCategory,

    refetchData,
    categoryStore,
    categoryUpdate,
    categoryDelete,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  }
}
