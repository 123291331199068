<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add">

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-form @submit.prevent>
          <b-card
            no-body
            class="invoice-preview-card"
          >
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">

              <div class="d-flex justify-content-between flex-column invoice-spacing mt-0">

                <!-- Header: Left Content -->
                <div class="flex-grow-1">
                  <h6 class="mb-0">
                    Título del Reto:
                  </h6>

                  <b-form-input
                    id="name"
                    v-model="bannerData.name"
                    :disabled="loading"
                    autofocus
                    trim
                  />

                  <!-- <h6 class="mb-0 mt-2">
                    Tipo de Banner:
                  </h6>
                  <v-select
                    v-model="bannerData.type"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="types"
                    label="name"
                    input-id="invoice-data-client"
                    :clearable="false"
                    :disabled="loading"
                    @input="val => changeType(val)"
                  />

                  <div
                    v-if="bannerData.type && bannerData.type.id === 2"
                    class="d-flex align-items-center mt-2"
                  >
                    <label
                      for="input-show"
                      class="mb-0 mr-1"
                    >Cuando mostrar</label>
                    <b-form-checkbox
                      id="input-show"
                      v-model="bannerData.alwaysShow"
                      :disabled="loading"
                      switch
                    />
                    <label
                      v-if="!bannerData.alwaysShow"
                      class="mb-0"
                    >Solo una vez</label>
                    <label
                      v-else
                      class="mb-0"
                    >Siempre</label>
                  </div> -->

                </div>

                <!-- Header: Right Content -->
                <div class="d-flex flex-wrap flex-grow-1 mt-2">
                  <div class="flex-grow-1 mb-1 pr-2">
                    <h6 class="title">
                      Fecha / hora de inicio:
                    </h6>
                    <flat-pickr
                      v-model="bannerData.fromDate"
                      class="form-control w-100"
                      :config="{ enableTime: true, dateFormat: 'Y-m-d H:i'}"
                      :disabled="loading"
                    />
                  </div>
                  <div class="flex-grow-1 mb-1">
                    <h6 class="title">
                      Fecha / hora de término:
                    </h6>
                    <flat-pickr
                      v-model="bannerData.toDate"
                      class="form-control w-100"
                      :config="{ enableTime: true, dateFormat: 'Y-m-d H:i'}"
                      :disabled="loading"
                    />
                  </div>
                </div>
              </div>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing">

            <!-- Note -->
            <b-card-body class="invoice-padding d-flex gap-2 pt-0">
              <div class="flex-grow-1">
                <span class="font-weight-bold">Monto mínimo de participación: </span>
                <b-form-input
                  v-model="bannerData.min_amount"
                  :disabled="loading"
                  trim
                  type="number"
                />
              </div>
              <div class="flex-grow-1">
                <span class="font-weight-bold">Cuota mínima de participación: </span>
                <b-form-input
                  v-model="bannerData.min_share"
                  :disabled="loading"
                  trim
                  type="number"
                />
              </div>
              <div class="flex-grow-1">
                <span class="font-weight-bold">Multiplicador: </span>
                <b-form-input
                  v-model="bannerData.multiplier"
                  :disabled="loading"
                  trim
                  type="number"
                />
              </div>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing">

            <!-- Note -->
            <b-card-body class="invoice-padding pt-0">
              <span class="font-weight-bold">Descripción: </span>
              <b-form-textarea
                v-model="bannerData.note"
                :disabled="loading"
              />
            </b-card-body>

            <hr class="invoice-spacing">

            <!-- <b-card-body>
              <div
                class="d-flex align-items-center"
                :style="isDragging ? 'pointer-events: none' : ''"
              >
                <b-form-file
                  ref="refInputGlobalEl"
                  accept=".jpg, .png, .webp"
                  placeholder="Arraste y suelte o haga clic para subir las imagenes"
                  multiple
                  :disabled="loading"
                  @change="changeInputFile"
                />
              </div>
            </b-card-body> -->

            <challenge-participants />
          </b-card>
        </b-form>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions mt-md-0 mt-2"
      >
        <!-- Action Buttons -->
        <b-card>
          <!-- Payment Method -->
          <div class="mb-2">
            <!-- <b-form-group
              label="Posición"
              label-for="input-position"
            >
              <b-form-input
                id="input-position"
                v-model="bannerData.position"
                :disabled="loading"
                type="number"
                trim
              />
            </b-form-group> -->

            <b-form-group
              label="Donde mostrar"
              label-for="input-blacklist"
            >
              <v-select
                v-model="bannerData.blacklist"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="countries"
                :disabled="loading"
                input-id="input-blacklist"
                multiple
                :close-on-select="false"
              />
            </b-form-group>

            <hr class="invoice-spacing">

            <b-form-group
              label="Estado"
              label-for="input-status"
            >
              <v-select
                v-model="bannerData.status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statuses"
                input-id="input-status"
                class="payment-selector"
                :clearable="false"
                :disabled="loading"
                label="text"
              />
            </b-form-group>
          </div>

          <hr class="invoice-spacing">

          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            :disabled="loading"
            block
            @click="onSubmit"
          >
            <template v-if="loading">
              <b-spinner
                class="mr-1"
                label="Small Spinning"
                small
              />
              Guardando...
            </template>
            <template v-else>
              Guardar
            </template>
          </b-button>

          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            :disabled="loading"
            block
            type="button"
            @click="saveAndNew"
          >
            <template v-if="loading">
              <b-spinner
                class="mr-1"
                label="Small Spinning"
                small
              />
              Guardando...
            </template>
            <template v-else>
              Guardar y crear otro
            </template>
          </b-button>
        </b-card>
      </b-col>
    </b-row>

    <!-- <invoice-sidebar-add-new-customer /> -->

    <categories-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      @refetchData="refetchDataCategories"
    />

    <backdrop-files v-if="isDragging" />
  </section>
</template>

<script>
// import Logo from '@core/layouts/components/Logo.vue'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BForm,
  BFormGroup,
  BFormTextarea,
  // BFormCheckbox,
  VBToggle,
  BFormInput,
  // BMedia,
  // BCardText,
  // BMediaAside,
  // BMediaBody,
  // BImg,
  // BFormFile,
  // BLink,
  BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment-timezone'
import { useInputImageRenderer, useFileToBase64 } from '@core/comp-functions/forms/form-utils'
import invoiceStoreModule from '../../banner/bannerStoreModule'
// import InvoiceSidebarAddNewCustomer from '../BannerSidebarAddNewCustomer.vue'
// import useBanners from '../../banner/useBanners'
import useCountries from '../../countries/useCountries'
import useLangs from '../../langs/useLangs'
import useCategories from '../../categories/useCategories'
import CategoriesAddNew from '../../categories/CategoriesAddNew.vue'
import BackdropFiles from '../../banner/BackdropFiles.vue'
import ChallengeParticipants from '../ChallengeParticipants.vue'

const KEY_INVALID = 'INVALID'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    // BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    // BInputGroup,
    // BInputGroupPrepend,
    BFormTextarea,
    // BFormCheckbox,
    // BPopover,
    flatPickr,
    vSelect,
    // Logo,
    // InvoiceSidebarAddNewCustomer,
    // BMedia,
    // BCardText,
    // BMediaAside,
    // BMediaBody,
    // BImg,
    // BFormFile,
    // BLink,
    BSpinner,
    CategoriesAddNew,
    BackdropFiles,
    ChallengeParticipants,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,

  },
  mixins: [heightTransition],
  watch: {
    'bannerData.allRequiredAuth': {
      handler(val) {
        this.bannerData.banners.forEach(banner => {
          banner.files.forEach(file => {
            // eslint-disable-next-line no-param-reassign
            file.requiredAuth = val
          })
        })
      },
    },
  },
  mounted() {
    // this.initTrHeight()
  },
  created() {
    // window.addEventListener('resize', this.initTrHeight)
    // console.log('mounted')
    window.addEventListener('dragover', this.dragover)
    window.addEventListener('dragleave', this.dragleave)
    window.addEventListener('drop', this.drop)
  },
  destroyed() {
    // window.removeEventListener('resize', this.initTrHeight)
    // console.log('destroyed')
    window.removeEventListener('dragover', this.dragover)
    window.removeEventListener('dragleave', this.dragleave)
    window.removeEventListener('drop', this.drop)
  },
  methods: {
    addNewItemInItemForm() {
      this.$refs.form.style.overflow = 'hidden'
      this.bannerData.banners.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)))

      // this.$nextTick(() => {
      //   this.trAddHeight(this.$refs.row[0].offsetHeight)
      //   setTimeout(() => {
      //     this.$refs.form.style.overflow = null
      //   }, 350)
      // })
    },
    removeItem(index) {
      this.bannerData.banners.splice(index, 1)
    },
    removeImage(index, name) {
      this.bannerData.banners[index].files = this.bannerData.banners[index].files.filter(file => file.name !== name)
    },
    removeItemInvalid(index) {
      this.filesInvalid.splice(index, 1)
    },
    removeImageInvalid(index, name) {
      this.filesInvalid[index].files = this.filesInvalid[index].files.filter(file => file.name !== name)
    },
    initTrHeight() {
      // this.trSetHeight(null)
      // this.$nextTick(() => {
      //   this.trSetHeight(this.$refs.form.scrollHeight)
      // })
    },
    addNewItemInUrlForm() {
      this.bannerData.urls.push(JSON.parse(JSON.stringify(this.itemFormBlankUrl)))
    },
    removeLang(index) {
      this.bannerData.urls.splice(index, 1)
    },

    // Methods Drag and drop file
    dragover(e) {
      e.preventDefault()
      this.isDragging = true
    },
    dragleave() {
      this.isDragging = false
    },
    drop(e) {
      e.preventDefault()
      this.changeInputFile(e.dataTransfer.files)
      this.isDragging = false
    },

    //
    async changeInputFile(e) {
      let inputFiles = []

      if (e instanceof Event) {
        inputFiles = e.target.files
      } else if (e instanceof FileList) {
        inputFiles = e
      } else if (Array.isArray(e)) {
        inputFiles = e
      } else {
        inputFiles = this.$refs.refInputGlobalEl?.files ?? []
      }

      const arrFiles = []
      const regex = new RegExp(/^\[([\w-]+)\]$/i)

      const result = (
        await Promise.allSettled(
          Array.from(inputFiles).map(async file => {
            const txt = file.name.split('_')

            // if (txt.length !== 4) {
            //   throw new Error('Error: La imagen no cumple con el formato adecuado de nombre-imagen_{ISO-PAIS}_{ISO-IDIOMA}_{PANTALLA}.(png, jpg)')
            // }

            const base64 = await useFileToBase64(file)

            if (txt.length !== 4) {
              console.error(`Error: La imágen "${file.name}" no cumple con el formato adecuado`)
              return {
                country: KEY_INVALID,
                country_iso: KEY_INVALID,
                lang: txt.at(-2).toUpperCase(),
                type: txt.at(-1).split('.').at(-2).toUpperCase(),
                ext: txt.at(-1).split('.').at(-1),
                name: file.name,
                file: base64,
                requiredAuth: false,
              }
            }

            return {
              country: txt.at(-3).toUpperCase(),
              country_iso: txt.at(-3).toUpperCase(),
              lang: txt.at(-2).toUpperCase(),
              type: txt.at(-1).split('.').at(-2).toUpperCase(),
              ext: txt.at(-1).split('.').at(-1),
              name: file.name,
              file: base64,
              requiredAuth: false,
            }
          }),
        )
      )
        .filter(resp => resp.status === 'fulfilled')
        .map(resp => resp.value)
        .reduce((arr, file) => {
          if (regex.test(file.country_iso) || regex.test(file.lang)) {
            const countries = regex.test(file.country_iso) ? regex.exec(file.country_iso)[1].split('-') : [file.country_iso]
            const langs = regex.test(file.lang) ? regex.exec(file.lang)[1].split('-') : [file.lang]

            countries.forEach(country => {
              langs.forEach(lang => {
                const split = file.name.split('_')
                const title = split.slice(0, -3).join('_')

                arr.push({
                  ...file,
                  country,
                  country_iso: country,
                  lang,
                  name: `${title}_${country}_${lang}_${split.at(-1)}`,
                })
              })
            })
          } else {
            arr.push(file)
          }

          return arr
        }, [])

      result.forEach(file => {
        const index = arrFiles.findIndex(f => f.country_iso === file.country_iso)

        if (file.country_iso === KEY_INVALID) {
          if (!this.filesInvalid.length) {
            this.filesInvalid.push({
              country: file.country,
              country_iso: file.country_iso,
              files: [{
                ...file,
                country_iso: file.country_iso,
              }],
            })
          } else {
            this.filesInvalid[0].files.push({
              ...file,
              country_iso: file.country_iso,
            })
          }

          return
        }

        if (index === -1) {
          arrFiles.push({
            country: file.country,
            country_iso: file.country_iso,
            files: [
              {
                name: file.name,
                type: file.type,
                file: file.file,
                lang: file.lang,
                ext: file.ext,
                requiredAuth: file.requiredAuth,
                country_iso: file.country_iso,
              },
            ],
          })
        } else {
          arrFiles[index].files.push({
            name: file.name,
            type: file.type,
            file: file.file,
            lang: file.lang,
            ext: file.ext,
            requiredAuth: file.requiredAuth,
            country_iso: file.country_iso,
          })
        }
      })

      if (!this.bannerData.banners || !this.bannerData.banners.length) {
        this.bannerData.banners = arrFiles
      } else {
        const prevBanners = JSON.parse(JSON.stringify(this.bannerData.banners))

        arrFiles.forEach(banner => {
          const index = prevBanners.findIndex(b => b.country_iso === banner.country_iso)
          if (index === -1) {
            prevBanners.push(banner)
          } else {
            banner.files.forEach(file => {
              const fileIndex = prevBanners[index].files.findIndex(f => f.name === file.name)
              if (fileIndex === -1) {
                prevBanners[index].files.push(file)
              } else {
                prevBanners[index].files[fileIndex] = {
                  ...prevBanners[index].files[fileIndex],
                  ...file,
                }
              }
            })
          }
        })

        this.bannerData.banners = prevBanners
      }

      this.$refs.refInputGlobalEl.reset()
    },
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    const itemFormBlankItem = {
      id: null,
      country: '',
      country_iso: '',
      files: [
        {
          name: '',
          file: '',
          type: 'desktop',
        },
        {
          name: '',
          file: '',
          type: 'tablet',
        },
        {
          name: '',
          file: '',
          type: 'mobile',
        },
      ],
    }

    const itemFormBlankUrl = {
      lang: '',
      url: '',
    }

    const bannerDataDefault = {
      id: null,
      name: '',
      type: null,
      alwaysShow: false,
      fromDate: moment().tz('America/Santiago').format(),
      toDate: '',
      // items: [JSON.parse(JSON.stringify(itemFormBlankItem))],
      banners: [],
      urls: [JSON.parse(JSON.stringify(itemFormBlankUrl))],
      note: '',
      status: {
        text: 'No Publicado',
        value: 'NOT_PUBLISHED',
      },
      blacklist: [],
      placeToShow: [],
      allRequiredAuth: false,
      position: null,
      min_amount: 0,
      min_share: 0,
      multiplier: 2,
    }

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const types = ref([
      {
        id: 1,
        name: 'Banner',
        value: 'banner',
        children: [],
      },
      {
        id: 2,
        name: 'Popup',
        value: 'popup',
        children: [],
      },
    ])
    const isAddNewUserSidebarActive = ref(false)

    // const { bannerStore } = useBanners()
    const { fetchLangsSelector } = useLangs()
    const { fetchCountriesSelector } = useCountries()
    const { fetchCategoriesSelector } = useCategories()
    const countries = ref([])
    const languages = ref([])
    const categories = ref([])
    const loading = ref(false)
    const showInfo = ref(false)
    const isDragging = ref(false)
    const filesInvalid = ref([])

    onMounted(async () => {
      const countryList = await fetchCountriesSelector()
      const langList = await fetchLangsSelector()
      let categoryList = await fetchCategoriesSelector()

      categoryList = categoryList.map(category => ({ ...category, text: category.label }))

      const typesconfig = JSON.parse(JSON.stringify(types.value))
      typesconfig[0].children = categoryList.filter(category => category.type === 'banner')
      typesconfig[1].children = categoryList.filter(category => category.type === 'popup')

      countries.value = countryList
      languages.value = langList
      categories.value = categoryList
      types.value = typesconfig
    })

    const refInputGlobalEl = ref(null)
    const refInputEl = ref(null)
    const refPreviewEl = ref(null)

    const placeToShow = ref([])

    const statuses = ref([
      { text: 'Publicado', value: 'PUBLISHED' },
      { text: 'No Publicado', value: 'NOT_PUBLISHED' },
    ])

    const urls = ref([])

    // store.dispatch('app-invoice/fetchClients')
    //   .then(response => {
    //     console.log(response.data)
    //     clients.value = response.data
    //   })

    const bannerData = ref({ ...bannerDataDefault })

    const changeType = val => {
      bannerData.value.alwaysShow = false
      bannerData.value.placeToShow = []
      placeToShow.value = val.children
    }

    const formatData = () => {
      const form = JSON.parse(JSON.stringify(bannerData.value))

      form.placeToShow = placeToShow.value.filter(place => place.active)

      return form
    }

    const onSubmit = async () => {
      if (loading.value) return

      loading.value = true

      try {
        const form = formatData()
        console.log({ form })
        await new Promise(resolve => setTimeout(resolve, 1500))
        // await bannerStore(form)

        router.back()
      } catch (error) {
        console.log(error)
      } finally {
        loading.value = false
      }
    }

    const saveAndNew = async () => {
      if (loading.value) return

      loading.value = true

      try {
        const form = formatData()
        console.log({ form })
        await new Promise(resolve => setTimeout(resolve, 1500))
        // await bannerStore(form)

        bannerData.value = JSON.parse(JSON.stringify({ ...bannerDataDefault }))
        placeToShow.value = []
        filesInvalid.value = []
      } catch (error) {
        console.log(error)
      } finally {
        loading.value = false
      }
    }

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => { refPreviewEl.value.src = base64 })

    const refetchDataCategories = async () => {
      let categoryList = await fetchCategoriesSelector()

      categoryList = categoryList.map(category => ({ ...category, text: category.label }))

      const typesconfig = JSON.parse(JSON.stringify(types.value))
      typesconfig[0].children = categoryList.filter(category => category.type === 'banner')
      typesconfig[1].children = categoryList.filter(category => category.type === 'popup')

      categories.value = categoryList
      types.value = typesconfig

      const prevPlaceToShow = [...placeToShow.value]

      categoryList
        .filter(category => category.type === bannerData.value.type.value)
        .forEach(category => {
          if (!prevPlaceToShow.find((c => c.id === category.id))) {
            prevPlaceToShow.push(category)
          }
        })

      placeToShow.value = prevPlaceToShow
    }

    return {
      bannerData,
      types,
      placeToShow,
      statuses,
      changeType,
      itemFormBlankItem,
      countries,
      refInputGlobalEl,
      refInputEl,
      refPreviewEl,
      inputImageRenderer,
      urls,
      itemFormBlankUrl,
      languages,
      categories,
      onSubmit,
      saveAndNew,
      isAddNewUserSidebarActive,
      refetchDataCategories,
      showInfo,
      isDragging,
      loading,
      filesInvalid,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
