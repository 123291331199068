<template>
  <div class="backdrop-files">
    <feather-icon
      icon="UploadCloudIcon"
      size="90"
      class="mb-2"
    />
    <h4>{{ text }}</h4>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: 'Suelta las imágenes para subirlas al banner',
    },
  },
}
</script>
